export const moneyFormatter = cell => {
  if (!cell) return '0 zł'

  const value = Number(cell.toString().replace(',', '.'))

  return `${value.toLocaleString('pl-PL', {
    style: 'currency',
    currency: 'PLN',
    minimumFractionDigits: 2
  })}`
}
