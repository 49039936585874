import classNames from 'classnames';
import React from 'react';

import styles from './my-hamburger.module.scss'

type Props = {
  isOpen: boolean,
  toggle: () => void
};

export const MyHamburgerComponent: React.FC<Props> = ({
  isOpen,
  toggle
}) => {
  const onClick = (event) => {
    event.preventDefault()
    toggle()
  }
  return (
    <a onClick={onClick} href="#">
      {!isOpen && <img src="/svgs/hamburger.svg" width={30} role="button" alt="hamburger" />}
      {isOpen && <img src="/svgs/menu_close.svg" width={22} className="mr-1" role="button" alt="close" />}
    </a>
  );
};
