import classNames from 'classnames'
import React from 'react'

import styles from './emoji.module.scss'
import { EmojiProps } from './emoji.types'

export const Emoji = ({ label, symbol, className }: EmojiProps) => (
  <span
    className={classNames([styles.emoji, className])}
    role="img"
    aria-label={label || ''}
    aria-hidden={label ? 'false' : 'true'}
  >
    {symbol}
  </span>
)
