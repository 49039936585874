import { translate } from '../../../client/common/translatable/translatable'
import { yup } from '../../../client/common/yup'

export const userSetPasswordSchema = yup.object().shape({
  password: yup.string().required().min(8).label(translate('Hasło')),
  passwordRepeat: yup
    .string()
    .oneOf([yup.ref('password')], translate('Obydwa pola z hasłem muszą być takie same')),
  token: yup.string().required().label(translate('Token'))
})
