import { useStateMachine } from 'little-state-machine'

import { setIsDiscountBarAction } from './actions'

export const useIsDiscountBar = () => {
  const {
    state: { isDiscountBar: isDiscountBarTrue },
    actions
  } = useStateMachine({
    setIsDiscountBarAction
  })

  const setIsDiscountBarTrue = () => {
    actions.setIsDiscountBarAction({ value: true })
  }

  return {
    setIsDiscountBarTrue,
    isDiscountBarTrue
  }
}
