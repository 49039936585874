import classNames from 'classnames'
import React from 'react';

import styles from './dropdown.module.scss';

interface DropdownProps {
  elementLabel: any;
  content: any;
  dropdownClass?: string;
  contentClass?: string;
}

export default function Dropdown({
  elementLabel,
  content,
  dropdownClass,
  contentClass
}: DropdownProps) {
  return (
    <div className={classNames([styles.dropdown, dropdownClass])}>
      <div className={styles.label}>{elementLabel}</div>
      <div className={classNames([styles.content, contentClass])}>{content}</div>
    </div>
  )
}
