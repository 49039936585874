import { yupResolver } from '@hookform/resolvers/yup'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { UserLoginDto } from 'piesotto-shared/admin-dtos/user-login/user-login.dto'
import { userLoginSchema } from 'piesotto-shared/admin-dtos/user-login/user-login.schema'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Alert, Button, Form, FormFeedback, FormGroup, Input, Label, ModalBody } from 'reactstrap'
import { fetchAuthorizedApi } from 'src/client/common/api-caller/api-caller'
import { ButtonWithSpinner } from 'src/client/common/button-with-spinner/button-with-spinner.component'
import { createReactstrapRegister } from 'src/client/common/forms/registration'
import { validationHandlers } from 'src/client/common/forms/validation-handlers'
import { translate } from 'src/client/common/translatable/translatable'
import { useCustomer } from 'src/client/hooks/useCustomer'
import { useCustomerSession } from 'src/client/hooks/useCustomerSession'
import { useGaEvent } from 'src/client/hooks/useGaEvent'
import { GAEvent } from 'src/client/lib/ga/gtag'
import { CookieStorage } from 'src/client/modules/plan/plan-storage/@enum/cookie-storage.enum'
import { createLink, Routes } from 'src/client/routes'

import styles from './login-form.module.scss'
import { OnLoginPopup } from './on-login-popup/on-login-popup'

type Props = {
  onForceClose: () => void
  onViewChange: () => void
}

export const LoginForm: React.FC<Props> = ({ onForceClose, onViewChange }) => {
  const { updateCurrentCustomer } = useCustomer()
  const { fetchCustomerSessionPlan } = useCustomerSession()
  const { updateGaEvent } = useGaEvent()

  const [apiError, setApiError] = useState<string>('')
  const [isRedirecting, setIsRedirecting] = useState<boolean>(false)
  const [isOnLoginPopupOpen, setIsOnLoginPopupOpen] = useState<boolean>(false)

  const t = translate

  const {
    register,
    formState: { errors },
    formState,
    handleSubmit
  } = useForm({
    resolver: yupResolver(userLoginSchema),
    defaultValues: {
      email: '',
      password: ''
    } as any
  })

  const getError = validationHandlers.createGetError(errors, translate)
  const isInvalid = validationHandlers.createIsInvalid(errors)

  const router = useRouter()

  const onOnLoginPopupClose = async () => {
    setIsOnLoginPopupOpen(false)
    await router.push(createLink(Routes.Pages_Home))
  }

  const onSubmit = async (values: UserLoginDto) => {
    const loginReponse = await fetchAuthorizedApi('/customer-session/login', {
      method: 'POST',
      body: JSON.stringify(values)
    })

    const login = await loginReponse.json()

    setIsRedirecting(true)

    if (!loginReponse.ok) {
      if (login.message === 'Missing credentials' || login.message === 'Wrong credentials') {
        setApiError(t('Wpisano niepoprawne dane logowania. Spróbuj jeszcze raz.'))
      } else {
        setApiError(t('Wystąpił niespodziewany błąd'))
      }

      setIsRedirecting(false)

      return
    }

    // for GA
    const event = {
      event: GAEvent.LOGIN,
      loginMethod: 'email',
      piesotto_user_id: login.customer.id
    }

    updateGaEvent(event)
    // end - for GA

    // Update current customer data in the LSM store
    updateCurrentCustomer(login.customer)

    // fetch current customer session plan date and update the store
    fetchCustomerSessionPlan()

    const onLoginPopupShown = Cookies.get(CookieStorage.ON_LOGIN_POPUP_SHOWN)

    // Turn on/off the on login popup
    const NO_ON_LOGIN_POPUP = true

    if (NO_ON_LOGIN_POPUP || onLoginPopupShown) {
      router.push(createLink(Routes.MyAccount_MyPlan))
    } else {
      setIsOnLoginPopupOpen(true)
    }
  }

  const customRegister = createReactstrapRegister(register)

  const isSpinning = formState.isSubmitting || isRedirecting

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <ModalBody className="position-relative pt-5 pb-4 pl-5 pr-5">
          <button type="button" className="close close-btn-in-modal" onClick={onForceClose}>
            &times;
          </button>

          <h1 className="h3">{t('Zaloguj się')}</h1>

          {apiError && (
            <FormGroup>
              <Alert color="danger">{translate(apiError)}</Alert>
            </FormGroup>
          )}

          <FormGroup>
            <Label for="email">{t('Email')}</Label>
            <Input
              autoComplete="username"
              className={styles.input}
              type="text"
              name="email"
              id="email"
              placeholder={t('Wpisz adres email')}
              invalid={Boolean(apiError) || isInvalid('email')}
              {...customRegister('email')}
            />

            {isInvalid('email') && <FormFeedback>{getError('email')}</FormFeedback>}
          </FormGroup>

          <FormGroup>
            <Label for="password">{t('Hasło')}</Label>
            <Input
              autoComplete="current-password"
              className={styles.input}
              type="password"
              name="password"
              id="password"
              placeholder={t('Wpisz hasło')}
              invalid={Boolean(apiError) || isInvalid('password')}
              {...customRegister('password')}
            />

            {isInvalid('password') && <FormFeedback>{getError('password')}</FormFeedback>}

            <div className="text-right">
              <Button
                className="p-0"
                onClick={onViewChange}
                color="link"
                disabled={formState.isSubmitting}
                data-test-id="forgot-password-action"
              >
                {t('Nie pamiętasz hasła?')}
              </Button>
            </div>
          </FormGroup>

          <FormGroup className="pt-3">
            <ButtonWithSpinner
              type="submit"
              block
              color="primary"
              size="lg"
              isSpinning={isSpinning}
            >
              {t('Zaloguj się')}
            </ButtonWithSpinner>
          </FormGroup>
        </ModalBody>
      </Form>

      <OnLoginPopup isOpen={isOnLoginPopupOpen} onClose={onOnLoginPopupClose} />
    </>
  )
}
