import Cookies from 'js-cookie'
import React, { useEffect } from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { translate } from 'src/client/common/translatable/translatable'
import { CookieStorage } from 'src/client/modules/plan/plan-storage/@enum/cookie-storage.enum'

import { OnLoginPopupProps } from './on-login-popup.types'

export const OnLoginPopup: React.FC<OnLoginPopupProps> = ({ isOpen, onClose }) => {
  useEffect(() => {
    if (isOpen) Cookies.set(CookieStorage.ON_LOGIN_POPUP_SHOWN, 'true')
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClosed={onClose} size="xl">
      <ModalHeader className="p-4">Ważna informacja</ModalHeader>
      <ModalBody className="p-4">
        {translate('Nasz udoskonalony kalkulator kaloryczny już działa!')}
        <br />
        <br />
        <span
          data-testid="modalBodyContent"
          dangerouslySetInnerHTML={{
            __html: translate(
              'Pssst… wielkość porcji dla Twojego pupila może się na nowo przeliczyć, gdy zmienisz coś na swoim koncie (wielkość paczki, smak itp.).<br />Ale spokojnie, możesz wrócić do poprzednichustawień klikając w zakładkę <a href="/moje-konto/moj-plan">Mój plan</a>. Wierzymy, że nikt nie zna lepiej Twojego pieska niż Ty sam!'
            )
          }}
        />
      </ModalBody>
      <ModalFooter className="justify-content-end p-4">
        <Button color="primary" onClick={onClose}>
          Idę dalej
        </Button>
      </ModalFooter>
    </Modal>
  )
}
