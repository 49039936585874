import * as yup from 'yup';
import * as pl from 'yup-locale-pl'
import * as creditCardValidator from 'credit-card-validator'
import printValue from './printValue';

yup.setLocale({
  ...pl.default,
  notType: ({ path, type, value, originalValue }) => {
    let isCast = originalValue != null && originalValue !== value;
    let msg =
      `${path} musi być typu \`${type}\`, ` +
      `lecz wpisana wartość to: \`${printValue(value, true)}\`` +
      (isCast
        ? ` (rzutowane z \`${printValue(originalValue, true)}\`).`
        : '.');

    if (value === null) {
      msg += `\n If "null" is intended as an empty value be sure to mark the schema as \`.nullable()\``;
    }

    return msg;
  },
})
yup.addMethod(yup.string, 'isValidCardNumber', function () {
  return this.test('isValidCardNumber', 'To nie jest poprawny numer karty', (value) => creditCardValidator.validateCardLuhn(value))
})

export {
  yup
}
