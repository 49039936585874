import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  Alert,
  Button,
  FormFeedback,
  FormGroup,
  Label,
  ModalBody,
  Input
} from 'reactstrap'

import { userSetPasswordSchema } from '../../../../../shared/admin-dtos/user-set-password/user-set-password.schema'
import { fetchApi } from '../../../../common/api-caller/api-caller'
import { ButtonWithSpinner } from '../../../../common/button-with-spinner/button-with-spinner.component'
import { createReactstrapRegister } from '../../../../common/forms/registration'
import { validationHandlers } from '../../../../common/forms/validation-handlers'
import { translate } from '../../../../common/translatable/translatable'

export const SetPasswordForm = ({ onForceClose, onGoToLogin, token }) => {
  const [apiError, setApiError] = useState<string | undefined>()
  const [isDone, setIsDone] = useState<boolean>(false)

  const { register, formState, handleSubmit } = useForm({
    defaultValues: {
      password: '',
      passwordRepeat: '',
      token
    },
    resolver: yupResolver(userSetPasswordSchema)
  })

  const onSubmit = async (values: any) => {
    const response = await fetchApi('/customer-session/set-password', {
      method: 'POST',
      body: JSON.stringify(values)
    })

    if (response.ok) {
      setIsDone(true)
    } else {
      const responseBody = await response.json()

      if (responseBody.message === 'No such token exists') {
        setApiError(
          translate(
            'Nie można ustawić hasła. Ten token został już wykorzystany. Zresetuj hasło, aby otrzymać nowy token'
          )
        )
      } else if (responseBody.message === 'Token has expired') {
        setApiError(
          translate('Ten token już wygasł. Zresetuj hasło ponownie, aby otrzymać nowy token')
        )
      } else {
        setApiError(translate('Wystąpił niespodziewany błąd'))
      }
    }
  }

  const isSpinning = formState.isSubmitting

  const getError = validationHandlers.createGetError(formState.errors, translate)
  const isInvalid = validationHandlers.createIsInvalid(formState.errors)

  const customRegister = createReactstrapRegister(register)

  if (isDone) {
    return (
      <ModalBody className="position-relative pt-5 pb-4 pl-5 pr-5">
        <h2>{translate('Hasło zostało ustawione')}</h2>
        <h5>Możesz teraz zalogować się na swoje konto</h5>
        <p>
          <Button size="lg" color="primary" block onClick={onGoToLogin}>
            {translate('Przejdź do logowania')}
          </Button>
        </p>
      </ModalBody>
    )
  }

  return (
    <ModalBody className="position-relative py-5 pb-4 pl-5 pr-5">
      <button type="button" className="close close-btn-in-modal" onClick={onForceClose}>
        &times;
      </button>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h1>{translate('Ustaw hasło')}</h1>
        <p className="text-muted">{translate('Ustawianie hasła dla konta')}</p>
        {apiError && <Alert color="danger">{apiError}</Alert>}
        <FormGroup>
          <Label>{translate('Hasło')}</Label>
          <Input
            type="password"
            placeholder={translate('Hasło')}
            {...customRegister('password')}
            invalid={isInvalid('password')}
          />
          <FormFeedback>{getError('password')}</FormFeedback>
        </FormGroup>

        <FormGroup>
          <Label>{translate('Powtórz hasło')}</Label>
          <Input
            type="password"
            placeholder={translate('Powtórz hasło')}
            {...customRegister('passwordRepeat')}
            invalid={isInvalid('passwordRepeat')}
          />
          <FormFeedback>{getError('passwordRepeat')}</FormFeedback>
        </FormGroup>
        <FormGroup className="pt-4">
          <ButtonWithSpinner block color="primary" size="lg" type="submit" disabled={isSpinning}>
            {translate('Ustaw hasło')}
          </ButtonWithSpinner>
        </FormGroup>

        <input type="hidden" {...register('token')} />
      </form>
    </ModalBody>
  )
}
