import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { Modal } from 'reactstrap'
import { createLink, Routes } from 'src/client/routes'

import { LoginForm } from './login-form'
import { ResetPasswordForm } from './reset-password/reset-password-form'
import { SetPasswordForm } from './set-password/set-password-form'

type Props = {
  isOpen: boolean
  initialView: Views
  settingPasswordToken?: string
  onForceClose: () => void
}

export enum Views {
  LOGIN = 'login',
  RESET_PASSWORD = 'resetPassword',
  SET_PASSWORD = 'setPassword'
}

export const LoginModal: React.FC<Props> = ({
  isOpen,
  initialView,
  onForceClose,
  settingPasswordToken
}) => {
  const router = useRouter()

  const [view, setView] = useState<Views>(initialView)

  useEffect(() => {
    setView(initialView)
  }, [initialView])

  const onForceCloseInner = () => {
    setView(Views.LOGIN)
    onForceClose()
  }

  const onGotoLogin = () => {
    router.replace(createLink(Routes.Pages_Login))
  }

  const onViewChange = () => {
    router.replace(createLink(Routes.Pages_ResetPassword))
  }

  return (
    <>
      <Modal isOpen={isOpen} centered>
        {view === Views.SET_PASSWORD && (
          <SetPasswordForm
            onForceClose={onForceCloseInner}
            token={settingPasswordToken}
            onGoToLogin={onGotoLogin}
          />
        )}
        {view === Views.LOGIN && (
          <LoginForm onForceClose={onForceCloseInner} onViewChange={onViewChange} />
        )}
        {view === Views.RESET_PASSWORD && (
          <ResetPasswordForm onForceClose={onForceCloseInner} onGoToLogin={onGotoLogin} />
        )}
      </Modal>
    </>
  )
}
