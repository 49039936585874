import React from 'react'
import { NavItem, NavLink } from 'reactstrap'
import { MyNavItemProps } from './@types/my-nav-item.types'
import styles from './my-nav-item.module.scss'
import { translate } from '../../../common/translatable/translatable'
import classNames from 'classnames'

export const MyNavItem: React.FC<MyNavItemProps> = ({
  link,
  onClick,
  text,
  active: login,
  className = '',
  linkClassName = ''
}) => {
  const t = translate

  if (!link && !onClick) {
    throw new Error('MyNavItem has to have `link` prop or `onClick` prop')
  }

  return (
    <NavItem className={className}>
      <NavLink
        href={link}
        onClick={onClick}
        className={classNames(login ? styles.myNavItemLogin : styles.myNavItem, linkClassName)}
      >
        {t(text)}
      </NavLink>
    </NavItem>
  )
}
