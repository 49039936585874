import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Form, FormGroup, Label, Input, Button, FormFeedback, Spinner, ModalBody } from 'reactstrap'
import { fetchApi } from 'src/client/common/api-caller/api-caller'
import { ButtonWithSpinner } from 'src/client/common/button-with-spinner/button-with-spinner.component'
import { createReactstrapRegister } from 'src/client/common/forms/registration'
import { validationHandlers } from 'src/client/common/forms/validation-handlers'
import { detectInbox } from 'src/client/common/inbox-detector/inbox-detector'
import { translate } from 'src/client/common/translatable/translatable'
import { UserResetPasswordDto } from 'src/shared/admin-dtos/user-reset-password/user-reset-password.dto'
import { userResetPasswordSchema } from 'src/shared/admin-dtos/user-reset-password/user-reset-password.schema'

export const ResetPasswordForm: React.FC<{
  onForceClose
  onGoToLogin
}> = ({ onForceClose, onGoToLogin }) => {
  const {
    register,
    formState,
    formState: { errors },
    handleSubmit
  } = useForm({
    resolver: yupResolver(userResetPasswordSchema)
  })

  const getError = validationHandlers.createGetError(errors, translate)
  const isInvalid = validationHandlers.createIsInvalid(errors)

  const [isFetching, setIsFetching] = useState(false)
  const [isFetched, setIsFetched] = useState(false)

  const [email, setEmail] = useState('')

  const onSubmit = async (values: UserResetPasswordDto) => {
    setIsFetching(true)

    const response = await fetchApi('/customer-session/reset-password', {
      method: 'POST',
      body: JSON.stringify(values)
    })

    if (response.ok) {
      setIsFetched(true)
      setEmail(values.email)
    }

    setIsFetching(false)
  }

  const customRegister = createReactstrapRegister(register)

  const isLoading = formState.isSubmitting || isFetching

  if (isFetched) {
    return (
      <ModalBody className="position-relative py-5 pb-4 pl-5 pr-5">
        <button type="button" className="close close-btn-in-modal" onClick={onForceClose}>
          &times;
        </button>
        <h1>{translate('Zapomniałem hasła')}</h1>
        <p
          dangerouslySetInnerHTML={{
            __html: translate(
              'Jeśli Twój adres email był poprawny, to właśnie wysłaliśmy do Ciebie wiadomość.<br /><br/>Aby ustawić hasło, musisz wejść na formularz ustawiania hasła klikając w link w otrzymanym przez Ciebie emailu.'
            )
          }}
        />
        {Boolean(detectInbox(email)) && (
          <Button color="primary" tag="a" href={detectInbox(email)} target="_blank" size="lg" block>
            {translate('Otwórz skrzynkę pocztową')}
          </Button>
        )}
        <Button
          color="primary"
          outline
          size="lg"
          block
          data-test-id="go-to-login"
          onClick={onGoToLogin}
        >
          {translate('Przejdź do logowania')}
        </Button>
      </ModalBody>
    )
  }

  return (
    <ModalBody className="position-relative pt-5 pb-4 pl-5 pr-5">
      <button type="button" className="close close-btn-in-modal" onClick={onForceClose}>
        &times;
      </button>
      <h1>{translate('Zapomniałem hasła')}</h1>
      <p
        dangerouslySetInnerHTML={{
          __html: translate(
            'Wpisz adres email, na który zarejestrowałeś swoje konto.<br />Jeśli adres email będzie poprawny, wyślemy Ci wiadomość z linkiem do wprowadzenia nowego hasła.'
          )
        }}
      />

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label for="email">{translate('Email')}</Label>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder={translate('Wpisz tu adres email')}
            {...customRegister('email')}
            invalid={isInvalid('email')}
          />
          <FormFeedback>{getError('email')}</FormFeedback>
        </FormGroup>

        <FormGroup className="pt-3">
          <ButtonWithSpinner type="submit" color="primary" size="lg" block disabled={isLoading}>
            {isLoading ? <Spinner color="light" /> : translate('Resetuj hasło')}
          </ButtonWithSpinner>
        </FormGroup>

        <FormGroup>
          <Button
            color="primary"
            outline
            onClick={onGoToLogin}
            size="lg"
            block
            data-test-id="go-to-login"
          >
            {translate('Przejdź do logowania')}
          </Button>
        </FormGroup>
      </Form>
    </ModalBody>
  )
}
