const createIsInvalid =
  <T extends string>(errors: any) =>
  (key: T): boolean => {
    const nestedProps = key.split('.').reverse()

    let ref = errors
    while (nestedProps.length > 0) {
      if (!ref) {
        return false
      }
      ref = ref[nestedProps.pop()]
    }

    return Boolean(ref && ref.message)
  }

const createGetError =
  <T extends string>(errors: any, t: (key: string, options?: any) => string) =>
  (key: T): string => {
    const nestedProps = key.split('.').reverse()

    let ref = errors
    while (nestedProps.length > 0) {
      if (!ref) {
        return ''
      }
      ref = ref[nestedProps.pop()]
    }

    if (!ref || !ref.message) {
      return ''
    }

    return !!ref && t(ref.message)
  }

export const validationHandlers = {
  createIsInvalid,
  createGetError
}
