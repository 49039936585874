import classNames from 'classnames'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Collapse,
  Container,
  Nav,
  Navbar,
  NavbarBrand,
  Row,
  NavLink
} from 'reactstrap'
import DiscountBar from 'src/client/common/discount-bar/discount-bar.component'
import { translate } from 'src/client/common/translatable/translatable'
import { useCustomer } from 'src/client/hooks/useCustomer'
import { useCustomerSession } from 'src/client/hooks/useCustomerSession'
import { LoginModal, Views } from 'src/client/modules/my-account/login/login-modal'
import { MyNavItem } from 'src/client/modules/static-pages/my-nav-item/my-nav-item.component'
import { createLink, Routes, menuFlavorsLinks } from 'src/client/routes'

import DiscountBarMainPage from '../../../common/discount-bar-main-page/discount-bar-main-page-component'
import Dropdown from '../../../common/dropdown/dropdown'
import { PlanModalComponent } from '../../../common/plan-modal/plan-modal.component'
import { usePlanModal } from '../../../hooks/usePlanModal'

import { MyNavbarProps } from './@types/my-navbar.props'
import { MyHamburgerComponent } from './my-hamburger-component'
import styles from './my-navbar.module.scss'

const MyNavbar: React.FC<MyNavbarProps> = ({
  isLoggedIn,
  activeTabIndex,
  settingPasswordToken,
  discountData,
  withoutLogo
}) => {
  const { clearCurrentCustomer } = useCustomer()
  const { clearCurrentCustomerSessionPlan } = useCustomerSession()
  const { toggleIsPlanModalOpen } = usePlanModal()
  const router = useRouter()

  const [navOpen, setNavOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)
  const toggle = () => setNavOpen(!navOpen)

  useEffect(() => {
    const offset = document.scrollingElement.scrollTop

    setIsScrolled(offset > 0)

    document.addEventListener('scroll', () => {
      const changedOffset = document.scrollingElement.scrollTop

      setIsScrolled(changedOffset > 0)
    })
  }, [])

  const isSticky = navOpen || isScrolled

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(Boolean(settingPasswordToken))

  const onLogoutClick = () => {
    clearCurrentCustomerSessionPlan()
    clearCurrentCustomer()
  }

  const onLoginClick = () => {
    router.replace(createLink(Routes.Pages_Login))
  }

  const onForceLoginModalClose = () => {
    setIsLoginModalOpen(false)
  }

  const hasUrlDiscount = /\?promo_code=/
  const currentRoute = router.asPath

  const bannerRoutes = [
    Routes.Pages_Home,
    Routes.Pages_About,
    Routes.Pages_Faq,
    Routes.Pages_HowItWorks
  ]

  const isRecipesRoutes = currentRoute.startsWith(Routes.Pages_Recipes)
  const hasPromoLink = hasUrlDiscount.test(currentRoute)

  const isCurrentRouteInBannerRoutes = bannerRoutes.includes(currentRoute as Routes)

  const hideMainPageDiscountComponent = false

  const discountOnMainPage =
    !hasPromoLink &&
    (isRecipesRoutes || isCurrentRouteInBannerRoutes) &&
    !isLoggedIn &&
    !hideMainPageDiscountComponent

  const discountBarComponent = !isScrolled && !isLoggedIn && (
    <DiscountBar discountData={discountData} />
  )

  return (
    <div>
      {discountOnMainPage ? <DiscountBarMainPage /> : discountBarComponent}
      <Container
        fluid
        className={classNames({
          [styles.navbarOpen]: navOpen,
          [styles.navbarClose]: !navOpen,
          'position-fixed': true,
          [styles.navbarContainer]: discountOnMainPage
        })}
        style={{
          zIndex: 400,
          backgroundColor: isSticky && !navOpen ? 'white' : '',
          padding: 0,
          boxShadow: isSticky ? '0 0 20px rgba(0, 0, 0, 0.15)' : '',
          marginTop: discountOnMainPage ? '40px' : 0
        }}
      >
        <Container
          fluid
          style={{
            backgroundColor: isSticky && !navOpen ? 'white' : ''
          }}
        >
          <Row className="px-md-3">
            <Col>
              <Navbar light expand="lg" className="p-3">
                {!withoutLogo && (
                  <NavbarBrand href={createLink(Routes.Pages_Home)}>
                    <img src="/svgs/logo.svg" width="140" alt="Piesotto" />
                  </NavbarBrand>
                )}

                <div className="d-lg-none">
                  <MyHamburgerComponent isOpen={navOpen} toggle={toggle} />
                </div>

                <Collapse isOpen={navOpen} navbar>
                  <Nav className="ml-auto" navbar>
                    <Dropdown
                      dropdownClass={styles.dropdownMenu}
                      contentClass={styles.dropdownMenuContent}
                      elementLabel={
                        <MyNavItem
                          active={activeTabIndex === 1}
                          link={createLink(Routes.Pages_Recipes)}
                          text="Nasze menu"
                          className="mt-mobile-50-px"
                        />
                      }
                      content={menuFlavorsLinks.map((link) => (
                        <NavLink href={createLink(link.route)}>{link.label}</NavLink>
                      ))}
                    />

                    <MyNavItem
                      active={activeTabIndex === 2}
                      link={createLink(Routes.Pages_HowItWorks)}
                      text="Jak to działa"
                      className="text-brown"
                    />

                    <MyNavItem
                      active={activeTabIndex === 3}
                      link={createLink(Routes.Pages_About)}
                      text="O Piesotto"
                      className="text-brown"
                    />

                    <MyNavItem
                      active={activeTabIndex === 4}
                      link={createLink(Routes.Pages_Faq)}
                      text="FAQ"
                      className="text-brown"
                    />

                    {!isLoggedIn && router.route !== Routes.Pages_Login && (
                      <MyNavItem onClick={onLoginClick} text="Zaloguj się" active />
                    )}

                    {!isLoggedIn && (
                      <>
                        <PlanModalComponent />
                        <div className="text-center mt-mobile-30-px mb-mobile-50-px pt-mobile-5-px">
                          <Button
                            className="mx-2 text-white rounded-pill font-weight-bold font-size-mobile-20 pl-mobile-50-px pr-mobile-50-px"
                            color="primary"
                            style={{ display: isScrolled || navOpen ? 'inline-block' : 'none' }}
                            onClick={toggleIsPlanModalOpen}
                          >
                            {translate('Zamów')}
                          </Button>
                        </div>
                      </>
                    )}

                    {isLoggedIn && (
                      <>
                        <MyNavItem
                          link={createLink(Routes.MyAccount_MyPlan)}
                          text="Moje konto"
                          className="mt-mobile-30-px pt-mobile-5-px"
                        />

                        <MyNavItem
                          className="mb-mobile-80-px"
                          link={createLink(Routes.Pages_Logout)}
                          text="Wyloguj się"
                          active
                          onClick={onLogoutClick}
                        />
                      </>
                    )}
                  </Nav>
                </Collapse>
              </Navbar>
            </Col>
          </Row>
        </Container>
      </Container>

      <LoginModal
        isOpen={isLoginModalOpen}
        initialView={settingPasswordToken ? Views.SET_PASSWORD : Views.RESET_PASSWORD}
        settingPasswordToken={settingPasswordToken}
        onForceClose={onForceLoginModalClose}
      />
    </div>
  )
}

export default MyNavbar
